.draggable-main {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f0f0f0;
  }

  .content {
    position: relative;
    height: 240px;
  }

  .content > div {
    position: absolute;
    width: 100%;
    height: 90px;
    overflow: visible;
    pointer-events: auto;
    transform-origin: 50% 50% 0px;
    border-radius: 5px;
    line-height: 90px;
    padding: 15px;
    font-size: 14.5px;
    background: lightblue;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
